import { ReactNode } from 'react'
import styles from './SectionTitle.module.scss';

const SectionTitle = ({ children }: { children: ReactNode }) => {
  return (
    <section className={styles.title}>
      { children }
    </section>
  )
}

export default SectionTitle
