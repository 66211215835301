import { NavLink } from "react-router-dom";

import type { RootState } from '../../store/index';
import { useSelector, useDispatch } from "react-redux";
import { toggleMenuActions } from '../../store/toggleMenuSlice';

import styles from './MenuLinks.module.scss';

const MenuLinks = ({ navClass }: { navClass: string }) => {
  const isOpenMenu = useSelector((state: RootState) => state.toggleMenu.isOpen);
  const dispatch = useDispatch();

  const toggleMenuHandler = () => {
    if(navClass !== 'nav-footer') {
      if (isOpenMenu) {
        dispatch(toggleMenuActions.close());
      } else {
        dispatch(toggleMenuActions.open());
      } 
    }
  };

  return (
    <ul className={ styles[navClass] }>
      <li onClick={toggleMenuHandler}>
        <NavLink
          to={'/'}
          className={`${styles['glitch-active']} ${styles['glitch-active-1']} hover-glitch`}
        >
          Home
        </NavLink>
      </li>
      <li onClick={toggleMenuHandler}>
        <NavLink
          to={'/about-us'}
          className={`${styles['glitch-active']} ${styles['glitch-active-2']} hover-glitch`}
        >
          About Us
        </NavLink>
      </li>
      <li onClick={toggleMenuHandler}>
        <NavLink
          to={'/contact'}
          className={`${styles['glitch-active']} ${styles['glitch-active-3']} hover-glitch`}
        >
          Let's talk
        </NavLink>
      </li>
    </ul>
  );
}

export default MenuLinks;
