import styles from './Ticker.module.scss';

const Ticker = ({ text }: { text: string }) => {
  return (
    <div className={styles['ticker-wrap']}>
      <div className={styles['ticker-wrap_text']}>
        <h2>{text}</h2>
        <span>·</span>
      </div>
      <div className={styles['ticker-wrap_text']}>
        <h2>{text}</h2> <span>·</span>
      </div>
    </div>
  )
}

export default Ticker;