const Path = (props: any) => (
  <path
    fill="transparent"
    strokeWidth="3.125"
    stroke="var(--primary-color)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }: any) => (
  <button onClick={toggle}>
    <svg width="36" height="26" viewBox="0 0 36 26" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M2 2H9.27787 M15.7549 2H34.0011"
        className="top"
        variants={{
          closed: { 
            d: "M2 2H9.27787 M15.7549 2H34.0011"
          },
          open: { 
            d: "M2.39301 24.1247L34.0597 2.45803"
          }
        }}
      />
      <Path d="M2 13L34 13" opacity="1" className="middle" />
      <Path
        d="M26.5 24H33.7779 M2 24H20.2462"
        className="bottom"
        variants={{
          closed: { 
            d: "M26.5 24H33.7779 M2 24H20.2462"
          },
          open: { 
            d: "M2 2L33.6667 23.6667"
          }
        }}
      />
    </svg>
  </button>
);
