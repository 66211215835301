import { configureStore } from "@reduxjs/toolkit";

import toggleMenuReducer from "./toggleMenuSlice";
import hoverProductItemsReducer from "./hoverProductItemsSlice";

export const store = configureStore({
  reducer: {
    toggleMenu: toggleMenuReducer,
    hoverProductItems: hoverProductItemsReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch