import { motion } from "framer-motion"

import styles from "./About.module.scss"
import { ScrollToTop, SectionDecription, SectionTitle } from "../../components";

const About = () => {
  return (
    <>
      <ScrollToTop />
      <motion.div
        initial={{ position: 'relative', left: '-100vh' }}
        animate={{ position: 'relative', left: '0' }}
        transition={{ duration: 0.7 }}
      >

        <SectionTitle>
          <h1>About<span>Us</span></h1>
        </SectionTitle>

        <section className={styles.video}>
          <video autoPlay loop muted width="100%" poster="/assets/video-about/poster-video.jpg">
            <source src="/assets/video-about/about-us.mp4" type="video/mp4"
            />
            <source src="/assets/video-about/about-us.webm" type="video/webm" />
            Sorry, your browser doesn't support videos.
          </video>
        </section>
      </ motion.div>

      <SectionDecription texts={[
        {
          id: 1,
          p: 'The DiAn Concepts team is fully immersed in the world of design and web technologies. We are passionate about pushing the boundaries of what is possible and creating innovative solutions that delight our users and customers. We are constantly exploring new technologies, tools, and techniques to help us stay ahead of the curve and deliver cutting-edge designs and experiences.'
        },
        { id: 2,
          p: 'We have a deep understanding of user needs and behaviors, and we use this knowledge to inform every aspect of our work, from the initial concept to the final implementation.'
        }
      ]} />
    </>
  )
}

export default About;
