import { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Ticker from '../ticker/Ticker';

import { hoverProductItemsActions } from '../../store/hoverProductItemsSlice';
import { useDispatch } from "react-redux";

import styles from './PortfolioFilterItem.module.scss';

interface PortfolioFilterItemProps {
  tag: string, 
  url: string, 
  imgUrl: string,
  TickerText?: string | null,
  data: any
}

const PortfolioFilterItem = ({
  tag, 
  url,
  imgUrl, 
  TickerText,
  data
}: PortfolioFilterItemProps) => {

  const usePortfolioFilterItemRef = useRef(null) as any;
  const dispatch = useDispatch();
  const { productId } = useParams();

  const IsTicker = () => {
    if(TickerText) {
      return (
        <div className={styles['portfolio-filter_item_ticker']}>
          <Ticker text={ TickerText } />
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    dispatch(hoverProductItemsActions.productItems({
      href: usePortfolioFilterItemRef.current.href,
      className: usePortfolioFilterItemRef.current.className
    }));
  }, [dispatch]);

  const RenderElement = () => {
    if(productId) {
      const { hoverTextDetail, url, productDescr } = data;

      return (
        <a 
          href={url} 
          rel="noreferrer"
          target="_blank"
          ref={usePortfolioFilterItemRef} 
          className={styles['portfolio-filter_item_main']} 
          data-hover={hoverTextDetail}
        >
          <div className={styles['portfolio-filter_item']}>
            <h4 className={styles['portfolio-filter_item_tag']}>{ parse(tag) }</h4>
            <div className={styles['portfolio-filter_item_img']}>
              <img src={ imgUrl } alt={ url } />
              <div className={styles['portfolio-filter_item_descr']}>
                <p>{ parse(productDescr) }</p>
              </div>
            </div>
          </div>

          <IsTicker />
        </a>
      )
    } else {
      const { hoverTextHome } = data;

      return (
        <Link 
          to={`/products/${url}`}
          ref={usePortfolioFilterItemRef} 
          className={styles['portfolio-filter_item_main']} 
          data-hover={hoverTextHome}
        >
          <div className={styles['portfolio-filter_item']}>
            <h4 className={styles['portfolio-filter_item_tag']}>{ parse(tag) }</h4>
            <div className={styles['portfolio-filter_item_img']}>
              <img src={ imgUrl } alt={ url } />
            </div>
          </div>

          <IsTicker />
        </Link>
      )
    }
  }

  return <RenderElement />
}

export default PortfolioFilterItem;
