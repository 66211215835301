import styles from './Socials.module.scss';

interface SocialItemProps {
  url: string, 
  icon: JSX.Element,
  stylesInline: object
}

const SocialItem = ({url, icon, stylesInline}: SocialItemProps) => {
  return (
    <a href={url} rel="noreferrer" target="_blank">
      <div className={`${styles['socials-item']}`} style={stylesInline}>
        {icon}
      </div>
    </a>
  )
}

export default SocialItem;
