import { createSlice } from "@reduxjs/toolkit";

export interface hoverProductItemsState {
  arrProductItems: any
}

const initialState: hoverProductItemsState = {
  arrProductItems: [],
}

const hoverProductItemsSlice = createSlice({
  name: 'hoverProduct',
  initialState,
  reducers: {
    productItems(state, action) {
      state.arrProductItems = [
        ...state.arrProductItems,
        {
          href: action.payload.href,
          className: action.payload.className
        }
      ];
      
    }
  }
});

export const hoverProductItemsActions = hoverProductItemsSlice.actions;

export default hoverProductItemsSlice.reducer;