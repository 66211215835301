import { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { CursorAnimation, FooterBottom, MenuLinks, StayConnected } from '../components';
import MainNavigation from '../components/main-navigation/MainNavigation';
import { ToastContainer } from 'react-toastify';
import useWindowSize from '../hooks/useWindowSize';

import 'react-toastify/dist/ReactToastify.css';

type ContextType = {
  cursorAnimate: any | null
};

const RootLayout = () => {
  const { width: screenWidth } = useWindowSize();
  const [elemCursorAnimate, setElemCursorAnimate] = useState() as any;

  const handleElemCursorAnimate = (ref: any) => {
    setElemCursorAnimate(ref);
  };

  return (
    <>
      <MainNavigation />
      <div className="App">
          <main>
            <Outlet context={[elemCursorAnimate]} />
          </main>
          <StayConnected />
          <MenuLinks navClass='nav-footer' />
          <FooterBottom
            classFooter="page-footer"
            classFooterAnimate=""
            options={{
              showSocials: false,
              showLogo: true
            }}
          />
          {
            screenWidth <= 991 ? null : <CursorAnimation elemCursorAnimate={handleElemCursorAnimate} />
          }
      </div>
      <ToastContainer theme="dark" autoClose={8000} />
    </>
  )
}

export const useCursorAnimate = () => {
  return useOutletContext<ContextType>();
}

export default RootLayout;
