import { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

import type { RootState } from '../../store/index';
import { useSelector } from "react-redux";

import styles from './CursorAnimation.module.scss';

const CursorAnimation = ({elemCursorAnimate}: {elemCursorAnimate: any}) => {
  const useCursorAnimatte = useRef(null) as any;
  const hoverProductItems = useSelector((state: RootState) => state.hoverProductItems.arrProductItems);
  const { productId } = useParams();

  useEffect(() => {

    const cursorTag = useCursorAnimatte.current;
    const balls = cursorTag.querySelectorAll("div");
    const ballMsg = cursorTag.querySelector("div span");

    elemCursorAnimate(useCursorAnimatte);

    let aimX = 0;
    let aimY = 0;

    const handleHoverObservable = (image: any) => {
      ballMsg.classList.add("visible");
      ballMsg.innerHTML = image.getAttribute("data-hover");
    };

    const handleUnHoverObservable = () => {
      ballMsg.classList.remove("visible");
    };

    balls.forEach((ball: any, index: any) => {
      let currentX = 0;
      let currentY = 0;

      let speed = 0.05 - index * 0.005;

      const animate = function () {
        currentX += (aimX - currentX) * speed;
        currentY += (aimY - currentY) * speed;

        ball.style.left = currentX + "px";
        ball.style.top = currentY + "px";

        requestAnimationFrame(animate);
      };

      animate();
    });

    document.addEventListener("mousemove", function (event) {
      aimX = event.pageX;
      aimY = event.pageY;
    });

    let portfolioFilterItemsStore = [] as any;

    hoverProductItems.map((item: any) => {
      portfolioFilterItemsStore = document.querySelectorAll(`.${item.className}`);

      return null;
    });

    if(portfolioFilterItemsStore.length) {
      portfolioFilterItemsStore.forEach((image: any) => {
        image.addEventListener("mouseover", () => handleHoverObservable(image));
        image.addEventListener("mouseout", handleUnHoverObservable);
      });
    }

    return () => {
      window.removeEventListener('mouseover', handleHoverObservable);
      window.removeEventListener('mouseout', handleUnHoverObservable);
    }

  }, [hoverProductItems, elemCursorAnimate])

  return (
    <div 
      ref={useCursorAnimatte} 
      className={`${styles.cursors} ${productId ? styles['product-page'] : ''} cursors-hover`}>
      <div>
        <span></span>
      </div>
      {/* <div></div>
      <div></div> */}
    </div>
  )
}

export default CursorAnimation;