import { useState, useEffect, useCallback, useRef } from 'react';

import styles from './Header.module.scss';

import headerImg from '../../assets/001.jpg';

// START UTILS ------------------------------------------------------------------------------------------------
// const log = (text: string) => {
//   console.log(text);
// };
// EBD UTILS --------------------------------------------------------------------------------------------------

// START CANVAS FRAME SCRUBBER --------------------------------------------------------------------------------
const CanvasFrameScrubber = (() => {
  const create = (context: any, frames: any) => {
    let currentFrame = 0;

    const observer = {
      next: (percentage: any) => {
        const frameIndex = Math.floor((percentage * (frames.length - 1)) / 100);

        if (currentFrame === frameIndex) return;

        window.requestAnimationFrame(() => {
          const img = frames[frameIndex];

          if (img && img instanceof ImageBitmap) {
            context.drawImage(img, 0, 0);
          } else {
            console.error('Invalid image element');
          }
        });
      }
    };

    return observer;
  };

  return {
    create: create
  };
})();
// END CANVAS FRAME SCRUBBER ------------------------------------------------------------------------------------

// START FRAME UNPACKER -----------------------------------------------------------------------------------------
const FrameUnpacker = () => {
  const unpack = async (options: any) => {
    const urlPattern = options.urlPattern,
      start = options.start,
      end = options.end,
      padding = options.padding;

    const bitmaps: any[] = [];
    const calls = [];

    // const timeStart = performance.now();

    // download each frame image and prep it up
    for (let index = start; index <= end; index++) {
      const id = index.toString().padStart(padding, '0');
      const url = urlPattern.replace('{{id}}', id);

      calls.push(
        fetch(url).then(res =>
          res
            .blob()
            .then(blob =>
              createImageBitmap(blob).then(bitmap => bitmaps.push({ id: index, bitmap: bitmap }))
            )
        )
      );
    }

    // wait for all the downloads to finish... (a more eager implementation that starts putting
    // the scrubbing as soon as the first few frames are downloaded can also be done, but we'll
    // keep thing s simple for now)
    await Promise.all(calls);

    // sort the downloaded frame bitmaps in order, they could have been downloaded haphazardly
    bitmaps.sort((a, b) => {
      return a.id - b.id;
    });

    // once that's done, construct an array of just frames that would be returned
    const frames: any[] = [];
    bitmaps.map(bitmap => frames.push(bitmap.bitmap));

    // const timeDelta = performance.now() - timeStart;
    // log(`Average extraction time per frame: ${timeDelta / (end - start)}ms`);

    return frames;
  };

  return {
    unpack: unpack
  };
};
// END FRAME UNPACKER ------------------------------------------------------------------------------------------

const ImageLoad = () => {
  const [inProgress, setInProgress] = useState(false);
  const requestRef = useRef<number>();

  const animate = useCallback(() => {
    setInProgress(false);

    if (requestRef.current !== undefined) {
      requestRef.current = requestAnimationFrame(animate);
    }
  }, []);

  useEffect(() => {
    let animateTriggerStart = true;
    let animateTriggerEnd = false;

    const handleScrollObservable = () => {
      if (inProgress) return;
      setInProgress(true);
      observable._process();
      animate();
    };

    // START SCROLL OBSERVABLE -----------------------------------------------------------------------------
    function ScrollObservable(this: any) {
      this._observers = [];
    }

    ScrollObservable.prototype._process = function (this: any) {
      const divElement = document.querySelector('#canvas-container') as HTMLDivElement | null;
      let pointTriiger = true;
      let percentageAllAnimation = 0;

      if (divElement) {
        const rect = divElement.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (pointTriiger) {
          if (scrollTop >= 50 && animateTriggerStart) {

            if (animateTriggerStart) {
              const speed = 110;
              const increment = (speed / 100) * 1;
              const intervalIncrementId = setInterval(() => {

                if (percentageAllAnimation <= 100) {
                  this.publish(+percentageAllAnimation.toFixed(2));
                }

                if (percentageAllAnimation >= 100) {
                  clearInterval(intervalIncrementId);
                  animateTriggerEnd = true;
                  percentageAllAnimation = 100;
                } else {
                  percentageAllAnimation = Math.min(percentageAllAnimation + increment, 100);
                }
              }, 1000 / 60);

              animateTriggerStart = false;

            }
          }

          if (scrollTop <= 50 && animateTriggerEnd) {
            if (animateTriggerEnd) {
              const speed = 110;
              const decrement = (speed / 100) * -1;

              percentageAllAnimation = 100;

              const intervalDecrementId = setInterval(() => {

                if (percentageAllAnimation >= 0) {
                  this.publish(+percentageAllAnimation.toFixed(2));
                }

                if (percentageAllAnimation <= 0) {
                  clearInterval(intervalDecrementId);
                  animateTriggerStart = true;
                  percentageAllAnimation = 0;
                } else {
                  percentageAllAnimation = Math.min(percentageAllAnimation + decrement, 100);
                }
              }, 1000 / 60);


              animateTriggerEnd = false;
            }
          }
        } else {
          let scrollPercentage = (scrollTop / (rect.height / 2)) * 100;

          if (scrollPercentage >= 0 && scrollPercentage <= 100) {
            this.publish(+scrollPercentage.toFixed(2));
          }
        }
      }
    };

    ScrollObservable.prototype.subscribe = function (this: any, observer: any) {
      this._observers.push(observer);
    };

    ScrollObservable.prototype.publish = function (this: any, value: any) {
      this._observers.forEach((observer: any) => {
        observer.next(value);
      });
    };

    const observable = new (ScrollObservable as any)();
    // END SCROLL OBSERVABLE --------------------------------------------------------------------------------

    async function fetchImage() {
      const videoContainer = document.querySelector('#canvas-container');
      if (!videoContainer) {
        throw new Error('Element missing!');
      }

      const framesUrlPattern = '/assets/videos/frames/{{id}}.jpg';
      const framesUrlStart = 1;
      const framesUrlEnd = 46;
      const framesIdPadding = 3;

      // Initializing frames download...

      // const startTime = Date.now();

      const frames = await FrameUnpacker().unpack({
        urlPattern: framesUrlPattern,
        start: framesUrlStart,
        end: framesUrlEnd,
        padding: framesIdPadding,
      });

      // const endTime = Date.now();

      // log(`Took ${(endTime - startTime) / 1000} seconds.`);

      const canvas = document.createElement('canvas');
      canvas.classList.add('canvas');
      canvas.height = frames[0].height;
      canvas.width = frames[0].width;
      const context = canvas.getContext('2d')!;
      context.drawImage(frames[0], 0, 0);

      // Ready! Scroll to scrub.

      setTimeout(() => {
        videoContainer.appendChild(canvas);

        // Setting up scrubber...

        const observer = CanvasFrameScrubber.create(context, frames);
        observable.subscribe(observer);
      }, 1000);
    }

    fetchImage();

    window.addEventListener('scroll', handleScrollObservable);
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestRef.current!);
      requestRef.current = undefined;

      window.removeEventListener('scroll', handleScrollObservable);
    };
  }, [animate, inProgress]);

  return (
    <>
      <section className="canvas-container" id="canvas-container">
        <img src={headerImg} alt="DiAn Concepts" />
      </section>
    </>
  );
};

const CanvasLoadAnimation = () => {
  useEffect(() => {
    (async () => {
      const framesUrlElement = document.querySelector('input[name="frames-url"]') as any;
      if (!framesUrlElement) {
        throw new Error('Element missing!');
      }
    })();
  }, []);

  return (
    <>
      <ImageLoad />
      <input
        name="frames-url"
        type="hidden"
        value="/assets/videos/frames/{{id}}.jpg"
        data-frame-start="1"
        data-frame-end="46"
        data-frame-id-padding="1"
      />
    </>
  );
};

const Header = () => {
  return (
    <header className={styles['header-home-page']}>
      <div className={styles['header-home-page-animate']}>
        <CanvasLoadAnimation />
      </div>
      <div className={styles['header-home-page-content']}>
        <h5 className={styles['header-home-page-decription']}>
          <span>UI/UX Design</span>
          <span>3D Design</span>
          <span>Web Development</span>
        </h5>
        <h1><span>DIAN</span> CONCEPTS</h1>
      </div>
    </header>
  )
}

export default Header;