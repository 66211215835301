import { useEffect } from "react";
import { useAnimate, stagger } from "framer-motion";
import { MenuToggle } from "../menu/MenuToggle";
import { Menu } from "../menu/Menu";

import type { RootState } from '../../store/index';
import { useSelector, useDispatch } from "react-redux";
import { toggleMenuActions } from '../../store/toggleMenuSlice';

import styles from './MainNavigation.module.scss';
import Logo from "../logo/Logo";

function useMenuAnimation(isOpenMenu: boolean) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations: any = isOpenMenu
      ? [
          [
            "nav",
            { transform: "translateX(0%)" },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 }
          ],
          [
            "li",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
            { delay: stagger(0.05), at: "-0.1" }
          ],
          [
            ".menu-footer-animate",
            { opacity: 1 },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 }
          ]
        ]
      : [
          [
            ".menu-footer-animate",
            { opacity: 0 },
            { delay: stagger(0.05, { from: "last" }), at: "<" }
          ],
          [
            "li",
            { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
            { delay: stagger(0.1, { from: "last" }), at: "<" }
          ],
          ["nav", { transform: "translateX(300%)" }, { at: "-0.1" }]
        ];

    animate([
      [
        "path.top",
        { 
          d: isOpenMenu ? "M2.39301 24.1247L34.0597 2.45803" : "M2 2H9.27787 M15.7549 2H34.0011",
        },
        { at: "<" }
      ],
      ["path.middle", { opacity: isOpenMenu ? 0 : 1 }, { at: "<" }],
      [
        "path.bottom",
        { 
          d: isOpenMenu ? "M2 2L33.6667 23.6667" : "M26.5 24H33.7779 M2 24H20.2462",
        },
        { at: "<" }
      ],
      ...menuAnimations
    ]);
  }, [isOpenMenu, animate]);

  return scope;
}

const MainNavigation = () => {
  const isOpenMenu = useSelector((state: RootState) => state.toggleMenu.isOpen);
  const dispatch = useDispatch();

  const scope = useMenuAnimation(isOpenMenu);

  const toggleMenuHandler = () => {
    if(isOpenMenu) {
      dispatch(toggleMenuActions.close());
    } else {
      dispatch(toggleMenuActions.open());
    }
  };

  return (
    <div className={styles.navigation}>
      <Logo />
      <div className={styles['navigation-menu']} ref={scope}>
        <Menu />
        <MenuToggle toggle={toggleMenuHandler} />
      </div>
    </div>
  )
}

export default MainNavigation;
