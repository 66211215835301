import * as yup from "yup";

export const advancedSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  username: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  description: yup
    .string()
    .min(10, "Description must be at least 10 characters long")
    .required("Required"),
});