import { useParams } from "react-router-dom";
import { PortfolioFilterItems, ScrollToTop, SectionTitle } from "../../components";
import parse from 'html-react-parser';
import { motion } from "framer-motion"

import styles from './ProductFilterDetails.module.scss';
import stylesOverride from '../../components/portfolio-filter-item/PortfolioFilterItem.module.scss';

import dataPortfolioItem from "../../data-portfolio/data";
import Error from "../error/Error";

const ProductFilterDetails = () => {
  const { productId } = useParams();
  let projectItem = dataPortfolioItem.find(project => project.url === productId);

  const initPortfolioInfo = () => {
    const { tag } = projectItem as any;

    return (
      <>
        <ScrollToTop />
        
        <motion.div
          initial={{ position: 'relative', top: '-100vh' }}
          animate={{ position: 'relative', top: '0' }}
          transition={{ duration: 0.7 }}
        >
        <SectionTitle>
          <h1>{parse(tag)}</h1>
        </SectionTitle>
        </ motion.div>

        <section className={`${styles["product-detail"]} ${stylesOverride["product-detail-section"]}`}>
          <div className={styles["product-detail-wrapper"]}>
            <PortfolioFilterItems itemsID='page-product-items'/>
          </div>
        </section>
      </>
    );
  };

  return projectItem ? initPortfolioInfo() : <Error />;
}

export default ProductFilterDetails
