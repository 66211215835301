import { useEffect } from 'react';
import { useCursorAnimate } from '../../pages/Root';

import styles from './PortfolioFilter.module.scss';
import PortfolioFilterItems from './PortfolioFilterItems';

const removeVisibleCursorAlert = (cursorAnimate: any) => {
  if(cursorAnimate) {
    const cursorTag = cursorAnimate.current;
    const ballMsg = cursorTag.querySelector("div span");
    ballMsg.classList.remove("visible");
  }
};

const PortfolioFilter = () => {
  const [cursorAnimate] = useCursorAnimate() as any;

  useEffect(() => {
    return () => {
      removeVisibleCursorAlert(cursorAnimate);
    };
  }, [cursorAnimate]);

  return (
    <section className={styles['portfolio-filter']}>
      <h2>A DIGITAL PRODUCT AGENCY</h2>
      <div className={styles['portfolio-filter_item_main']}>
        <PortfolioFilterItems isTickerText={true} itemsID='page-home-filter' />
      </div>
    </section>
  )
}

export default PortfolioFilter;
