import { Link } from "react-router-dom";

import styles from './Logo.module.scss';
import logo from '../../assets/logo.png';

const Logo = () => {
  return (
    <div className={styles['navigation-logo']}>
      <Link to={'/'}>
        <img src={logo} alt="DiAn Concepts" />
      </Link>
    </div>
  )
}

export default Logo;
