import Logo from '../logo/Logo';
import Socials from '../socials/Socials';

import styles from './FooterBottom.module.scss';

interface FooterProps { 
  classFooter: string, 
  classFooterAnimate: string,
  options: any
};

const FooterBottom = ({ classFooter, classFooterAnimate, options }: FooterProps) => {
  const renderElements = () => {
    if(options.showSocials) {
      return <Socials 
      sizeSVG={{
        be: {
          width: '14',
          height: '9'
        },
        etsy: {
          width: '20',
          height: '9'
        }
      }}

      stylesInlineItem={{
        width: '36px', 
        height: '36px'
      }} 
    />
    }

    if(options.showLogo) {
      return <Logo />
    }
  }

  return (
    <footer>
      <div className={`${styles.footer} ${styles[classFooter]} ${classFooterAnimate} da-container`}>
        <div className="copyright">
          <p>© The DiAn Concepts. 2023 All rights reserved.</p>
        </div>
        {renderElements()}
      </div>
    </footer>
  )
}

export default FooterBottom;
