import PortfolioFilterItem from '../portfolio-filter-item/PortfolioFilterItem';
import dataPortfolioItem from '../../data-portfolio/data';
import { useParams } from 'react-router-dom';

interface PortfolioFilterItemProps {
  isTickerText?: boolean | null,
  itemsID: string
}

const PortfolioFilterItems = ({
  isTickerText = false,
  itemsID
}: PortfolioFilterItemProps) => {

  const { productId } = useParams();
  let data = [] as any;

  if(itemsID === 'page-home-filter') {
    data = dataPortfolioItem;
  } else if(itemsID === 'page-product-items') {
    dataPortfolioItem.map((project) => {
      if(project.url === productId) {
        data = project.productDetails;
      }

      return null;
    })
  }

  return (
    <>
    { data.map((portfolioFilterItem: any) =>
      <PortfolioFilterItem 
        key={portfolioFilterItem.id} 
        data={portfolioFilterItem}
        tag={portfolioFilterItem.tag}
        url={portfolioFilterItem.url}
        imgUrl={portfolioFilterItem.imgUrl}
        TickerText={isTickerText ? portfolioFilterItem.tickerText : ''}
      />
    )}
    </>
  )
}

export default PortfolioFilterItems
