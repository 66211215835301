import { useParams } from "react-router-dom";
import { FooterBottom, MainNavigation, SectionTitle, MenuLinks } from "../../components";

const Error = () => {
  const { productId } = useParams();

  return (
    <>
      {productId ? null : <MainNavigation />}
      <SectionTitle>
        <h1>Page <span>not found</span></h1>
      </SectionTitle>
      {productId ? null : <MenuLinks navClass='nav-footer' />}
      {productId ? null : <FooterBottom
        classFooter="page-footer"
        classFooterAnimate=""
        options={{
          showSocials: false,
          showLogo: true
        }}
      />}
    </>
  )
}

export default Error;
