import { createSlice } from "@reduxjs/toolkit";

export interface ToggleMenuState {
  isOpen: boolean
}

const initialState: ToggleMenuState = {
  isOpen: false,
}

const toggleMenuSlice = createSlice({
  name: 'toggleMenu',
  initialState,
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    }
  }
});

export const toggleMenuActions = toggleMenuSlice.actions;

export default toggleMenuSlice.reducer;