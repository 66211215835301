import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion } from "framer-motion"
import { toast } from 'react-toastify';
import { ScrollToTop, SectionDecription, SectionTitle } from "../../components";

import { Form, Formik } from "formik";
import { advancedSchema } from "../../schemas";
import CustomInput from "../../components/form-fields/CustomInput";
import CustomTextArea from "../../components/form-fields/CustomTextArea";

import styles from './Contact.module.scss';

const Contact = () => {
  const formSection = useRef() as any;

  const onSubmit = async (values: any, actions: any) => {
    if (values) {
      emailjs.sendForm(
        'service_vuqe87l',
        'template_iaxe6em',
        formSection.current,
        'LegfPXabFaqY-jwhF')
        .then(() => {
          toast.success("We'll be in touch shortly 👽", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }, (error) => {
          toast.error("Report bug via email, please 🥺", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          console.log('error.text', error.text);
        });

      actions.resetForm();
    }
  };

  return (
    <>
      <ScrollToTop />
      <motion.div
        initial={{ position: 'relative', left: '-100vh' }}
        animate={{ position: 'relative', left: '0' }}
        transition={{ duration: 0.7 }}
      >

        <SectionTitle>
          <h1>Let's<span>talk</span></h1>
        </SectionTitle>

        <SectionDecription texts={[
          {
            id: 1,
            p: "Our team is readily available to assist you in transforming your brand or product from good to exceptional, and it's as simple as a single click. To get started, just complete the form and provide us with more information about your project, or even something personal like your preferred ice cream flavor. We're open to discussing anything and everything, and we're excited to engage with you. So don't hesitate, fill out the form and let's begin the journey together."
          }
        ]} />
      </ motion.div>

      <section className={styles['contact-form']}>
        <Formik
          initialValues={{ username: "", email: "", description: "" }}
          validationSchema={advancedSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form ref={formSection} >

              <div className={styles['contact-form-inputs']}>
                <CustomInput
                  name="username"
                  type="text"
                  placeholder="What's your name?"
                />
                <CustomInput
                  name="email"
                  type="email"
                  placeholder="Your email"
                />
              </div>

              <CustomTextArea
                name="description"
                id="form-descr"
                placeholder="What can we help you with?"
              />
              <button className={`${styles.btn} ${styles['glitch-active']} ${styles['glitch-active-btn']} hover-glitch`} disabled={isSubmitting} type="submit">
                send
              </button>
            </Form>
          )}
        </Formik>

        <div className={styles['contact-form-other']}><span>or</span></div>
        <div className={styles['contact-form-email']}>
          <h4>Don't Be Shy: Send Us an Email</h4>
          <a href="mailto:info.dianconcepts@gmail.com">info.dianconcepts@gmail.com</a>
        </div>
      </section>
    </>
  )
}

export default Contact;
