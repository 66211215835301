import styles from './Menu.module.scss';

import FooterBottom from "../footer-bottom/FooterBottom";
import MenuLinks from "../menu-links/MenuLinks";

export function Menu() {
  return (
    <nav className={styles.menu}>
      
      <MenuLinks navClass='nav-menu' />

      <FooterBottom 
        classFooter="menu-footer" 
        classFooterAnimate="menu-footer-animate" 
        options={{
          showSocials: true,
          showLogo: false
        }}
      />
    </nav>
  );
}
