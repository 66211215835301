import styles from './SectionDecription.module.scss';

const SectionDecription = ({ texts }: { texts: any[] }) => {
  return (
    <section className={styles["section-decr"]}>
      {
        texts.map((item) => 
          <p key={item.id}>
            {item.p}
          </p>
        )
      }
    </section>
  )
}

export default SectionDecription;
