import { SvgIconBe, SvgIconLetsy } from '../svg-icons/SvgIcons';
import SocialItem from './SocialItem';
import styles from './Socials.module.scss';

interface SocialsProps {
  [propName: string]: any;
}

const Socials = ({sizeSVG, stylesInlineItem}: SocialsProps) => {
  return (
    <div className={styles.socials}>
      <SocialItem url="https://www.behance.net/dian_concepts" icon={<SvgIconBe widthSVG={sizeSVG.be.width} heightSVG={sizeSVG.be.height} />} stylesInline={stylesInlineItem} />
      <SocialItem url="https://www.etsy.com/shop/DIANDesignsCo" icon={<SvgIconLetsy widthSVG={sizeSVG.etsy.width} heightSVG={sizeSVG.etsy.height} />} stylesInline={stylesInlineItem} />
    </div>
  )
}

export default Socials;
