const dataPortfolioItem = [
  {
    id: 1,
    url: 'design',
    tag: 'des<span>ign</span>',
    imgUrl: '/assets/portfolio-filter/design.jpg',
    tickerText: 'Designing for Emotion: Crafting Designs that Evoke Feelings and Connection!',
    hoverTextHome: 'SHOW',
    productDetails: [
      {
        id: 1,
        tag: '<span>BUY</span> Inner Energy Poster Design',
        url: 'https://www.etsy.com/listing/1458677228/inner-energy-poster-design',
        imgUrl: '/assets/portfolio-filter/design/1/1.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'Poster Designs for "Inner Energy" are available in three distinct styles and can be downloaded digitally. Featuring a visually pleasing aesthetic with a minimalist approach, these posters make excellent printable artwork to showcase your taste in design and art. Customize your living and working spaces with a personalized touch.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 2,
        tag: '<span>BUY</span> Geometria 2 Poster Design',
        url: 'https://www.etsy.com/listing/1463851489/geometria-2-poster-design',
        imgUrl: '/assets/portfolio-filter/design/2/2.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'The "Geometria 2" poster design is a continuation of the "Geometria" design line, featuring an aesthetic and minimalist approach with abstract, geometric graphic art. These posters are suitable for digital display and can be printed as artwork to showcase your preferences in design and art. Use them to add a personalized touch to your living and working spaces.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 3,
        tag: '<span>BUY</span> Geometria Poster Design',
        url: 'https://www.etsy.com/listing/1463815647/geometria-poster-design',
        imgUrl: '/assets/portfolio-filter/design/3/3.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'The "Geometria" poster design has a simple yet visually appealing aesthetic with abstract, geometric graphic art, which is well-suited for digital display. You can use these posters as printable artwork to showcase your taste in design and art and personalize your living and working spaces.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 4,
        tag: '<span>BUY</span> Lakeside Poster Design',
        url: 'https://www.etsy.com/listing/1461085759/lakeside-poster-design',
        imgUrl: '/assets/portfolio-filter/design/4/4.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'The "Lakeside" poster design is an aesthetically pleasing poster with plant graphic art, featuring scenery and landscape design that is suitable for digital display. You can use this printable artwork to express your preferences in design and art, and add a personalized touch to your living and working spaces.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 5,
        tag: '<span>BUY</span> Currents Poster Design',
        url: 'https://www.etsy.com/listing/1458755645/currents-poster-design',
        imgUrl: '/assets/portfolio-filter/design/5/5.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'The "Currents" poster design offers three color variations and features an aesthetic design with plant graphic art that is suitable for digital display. You can use this printable artwork to showcase your taste in design and art and personalize your living and working spaces to add a unique touch.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 6,
        tag: '<span>BUY</span> Harmony Poster Design',
        url: 'https://www.etsy.com/listing/1441872654/harmony-poster-design',
        imgUrl: '/assets/portfolio-filter/design/6/6.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'The "Harmony" poster design is an aesthetically pleasing poster available in three color variations, featuring plant graphic art and a pleasing design suitable for digital display. By using this printable artwork, you can express your preferences in design and art, and add a unique touch to personalize your living and working spaces.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      }
    ]
  },
  {
    id: 2,
    url: 'web-development',
    tag: '<span>web</span> development',
    imgUrl: '/assets/portfolio-filter/web-development.jpg',
    tickerText: 'Web Magic: Transforming Ideas into Interactive Experiences',
    hoverTextHome: 'SHOW',
    productDetails: [
      {
        id: 1,
        tag: '<span>BUY</span> CreatoR | Figma Design',
        url: 'https://www.behance.net/gallery/168615101/CreatoR-Sleek-and-Minimalistic-Portfolio-Figma-Design',
        imgUrl: '/assets/portfolio-filter/web-development/1/1.jpg',
        hoverTextDetail: 'SHOP HERE',
        productDescr: 'If you are looking for a sleek and minimalistic portfolio Figma design for your business, CreatoR - Personal Portfolio Figma Template is the perfect choice. Based on Bootstrap and a 12-column responsive grid, it includes everything you need for website development. The Figma files are well-organized and easy to customize, and we offer you 4+ Figma files to choose from.<br><br><span>Click and go to our official account for more information and purchase.</span>'
      },
      {
        id: 2,
        tag: '<span>VIEW</span> DevRaiks| Portfolio site',
        url: 'https://www.behance.net/gallery/166096111/Dev-Raiks-Portfolio',
        imgUrl: '/assets/portfolio-filter/web-development/2/2.jpg',
        hoverTextDetail: 'VIEW HERE',
        productDescr: 'The portfolio site is tailored for front-end developer, highlighting latest projects and showcasing unique skills and experience in modern web development. With a personalized approach, it stands out in a competitive industry and offers cutting-edge designs and solutions to bring web development projects to life. Browse the portfolio site to learn more.<br><br><span>Click and learn more about the portfolio site.</span>'
      }
    ]
  }
];

export default dataPortfolioItem;