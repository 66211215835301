import { motion } from "framer-motion"
import { Header, PortfolioFilter, ScrollToTop } from "../../components";

const Home = () => {
  return (
    <>
    <ScrollToTop />
    <motion.div
      initial={{ position: 'relative', top: '-100vh' }}
      animate={{ position: 'relative', top: '0' }}
      transition={{ duration: 0.7 }}
    >
     <Header />
    </ motion.div>

    <PortfolioFilter />
    </>
  )
}

export default Home;
