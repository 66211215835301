import { useField } from "formik";

interface CustomTextAreaProps {
  name: string;
  id?: string;
  placeholder?: string;
}

const CustomTextArea = ({ ...props }: CustomTextAreaProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const [field, meta] = useField(props);

  return (
    <>
      <textarea
        {...field}
        {...props}
        className={meta.touched && meta.error ? "input-error" : ""}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
export default CustomTextArea;