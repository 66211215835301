import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { About, Contact, Error, Home, ProductFilterDetails, RootLayout } from './pages';

import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <Error />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/about-us', element: <About /> },
        { path: '/contact', element: <Contact /> },
        { path: '/products/:productId', element: <ProductFilterDetails /> }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
