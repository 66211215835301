import Socials from '../socials/Socials';

import styles from './StayConnected.module.scss';

const StayConnected = () => {
  return (
    <section id='footer-connect' className={styles['stay-connected']}>
      <h2 className="stay-connected-title">Stay Connected with Our Latest Ventures</h2>
      <div className={styles['stay-connected-content']}>
        <h3 className="title">Follow Us Here</h3>
        <div className="social">
          <Socials 
            sizeSVG={{
              be: {
                width: '30',
                height: '18'
              },
              etsy: {
                width: '48',
                height: '25'
              }
            }}

            stylesInlineItem={{
              width: '80px', 
              height: '80px'
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default StayConnected;
